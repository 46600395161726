import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import ImageAstro from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Bienvenue sur un beau site Gatsby.</p>
    <p>Top, on va faire un truc cool!</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <ImageAstro />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage
